import { render, staticRenderFns } from "./registerEntry_tl7.vue?vue&type=template&id=22a8a7c9&scoped=true"
import script from "./registerEntry_tl7.vue?vue&type=script&lang=js"
export * from "./registerEntry_tl7.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/registerEntry_tl7.scss?vue&type=style&index=0&id=22a8a7c9&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22a8a7c9",
  null
  
)

export default component.exports